@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'nprogress/nprogress.css';
@import '@fortawesome/fontawesome-svg-core/styles.css';

#nprogress .bar {
    background: limegreen !important;
}

#licenseListTable .ant-table-thead > tr > th {
    background-color: #d1d5db !important;
    color: black !important;
    text-align: left !important;
    border-top: 1px solid black !important;
    border-radius: 0 !important; 
}

#licenseListTable .ant-table-tbody > tr > td {
    border-top: 1px solid black !important;
}

#licenseListTable .ant-table-tbody > tr:last-child > td {
    border-bottom: 1px solid black !important;
}

.status-badge {
    display: inline-block;
    padding: 0.5em 1em;
    border-radius: 20px;
    color: white;
    text-align: center;
}

.status-sent {
    background-color: #7CFC00;
}

.status-failed {
    background-color: #FF0000;
}

.custom-arrow-white .ant-menu-submenu-arrow {
    color: white;
}

.no-hover:hover {
    background-color: transparent !important;
}
.ant-table-tbody > tr.no-hover:hover > td {
    background-color: transparent !important;
}